import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import DataProvider from "./Data/Context";
import Store from "./Data/Store";
import Routers from "./Routes";
import { SetAuthToken, SetDefaultHeaders } from "./Data/Config";
import { TOKEN } from "./Data/Actions/ActionTypes";
import { loadUser } from "./Data/Actions/AuthActions";
import DocumentMeta from "react-document-meta";
// import { getSettings } from "./Data/Reducer/SettingsReducer";

// Preloader
$(window).on("load", function () {
  $(".lds-ellipsis").fadeOut(); // will first fade out the loading animation
  $(".preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
  $("body").delay(333);
});
SetDefaultHeaders();

if (localStorage.getItem(TOKEN)) {
  SetAuthToken(localStorage.getItem(TOKEN));
}

const App = () => {
  useEffect(() => {
    Store.dispatch(loadUser());
    // Store.dispatch(getSettings());
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    let link2 = document.querySelector("link[rel~='apple-touch-icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (!link2) {
      link2 = document.createElement("link");
      link2.rel = "apple-touch-icon";
      document.getElementsByTagName("head")[0].appendChild(link2);
    }
    link.href = process.env.REACT_APP_IMAGE_URL;
    link2.href = process.env.REACT_APP_IMAGE_URL;
  }, []);

  const meta = {
    title: `${process.env.REACT_APP_NAME} website`,
    description: `${process.env.REACT_APP_NAME} is a website that provides you with easy access to data`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "data,airtime,cables,electricity, waec/neco pin",
      },
    },
  };

  return (
    <Provider store={Store}>
      <DataProvider>
        <Router>
          <DocumentMeta {...meta} />
          <Routers />
        </Router>
      </DataProvider>
    </Provider>
  );
};

export default App;
