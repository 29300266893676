/* eslint-disable jsx-a11y/no-distracting-elements */
import Nav from "../../Components/home-two/nav";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FaQuoteRight } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import "./homeTwo.css";
import Card from "../../Components/card/card";
import Google from "../../Assets/google.svg";
import Ios from "../../Assets/ios.svg";
import HeroImg from "../../Assets/hero.png";
import Star from "../../Assets/star.svg";
import Bg2 from "../../Assets/bg2.svg";
import Secure from "../../Assets/secure.svg";
import Satisfaction from "../../Assets/satisfaction.svg";
import Support from "../../Assets/support.svg";
import Instant from "../../Assets/instant.svg";
import Bg3 from "../../Assets/bg3.png";
import Dataicon from "../../Assets/dataicon.svg";
import AirtimeIcon from "../../Assets/airtimeicon.svg";
import ElectricityIcon from "../../Assets/electricityicon.svg";
import EducationIcon from "../../Assets/educationicon.svg";
// import Cableicon from "../../Assets/cableicon.svg";
import Tv from "../../Assets/tv.svg";
import Steps from "../../Assets/steps.svg";
import One from "../../Assets/one.svg";
import Two from "../../Assets/two.svg";
import Three from "../../Assets/three.svg";
import Right from "../../Assets/right.svg";
import Bg4 from "../../Assets/bg4.png";
import Iphone from "../../Assets/iphone.svg";
import Logowhite from "../../Assets/logowhite.svg";
import Facebook from "../../Assets/facebook.svg";
import Twitter from "../../Assets/x.png";
import Whatsapp from "../../Assets/whatsapp.svg";
import Instagram from "../../Assets/instagram.svg";
import Linkedin from "../../Assets/linkedin.svg";
import { IconContext } from "react-icons";
import Lines from "../../Assets/line.svg";
import People from "../../Assets/people.svg";
import Auto from "../../Assets/auto.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper/modules";
import "swiper/css";
import Company from "../../Assets/c.svg";
import { Link, useNavigate } from "react-router-dom";

const HomeTwo = () => {
  // const navigate = useNavigate();
  const [isPaused, setIsPaused] = useState(false);
  const [open, setOpen] = useState(null),
    [answer, setAnswer] = useState(false);
  const Arr = [
      {
        icon: Secure,
        text: "Secure and Convenient Transactions",
      },
      {
        icon: Support,
        text: "Exceptional Customer Support",
      },
      {
        icon: Satisfaction,
        text: "Optimum Satisfaction",
      },
      {
        icon: Instant,
        text: "Instant Delivery",
      },
    ],
    Arr2 = [
      {
        icon: Dataicon,
        titie: "Data Services",
        description:
          "Enjoy internet browsing always with our attractive data rates. Start your data reselling business with us today and enjoy profitable discounts for all networks.",
      },
      {
        icon: AirtimeIcon,
        titie: "Airtime Services",
        description:
          "Making an online recharge has become cheaper, fast and safe on Honourworld. We offer instant recharge of Airtime with discounts for all networks.",
      },
      {
        icon: ElectricityIcon,
        titie: "Electricity",
        description:
          "Recharge yout prepaid meter and receive instant token delivery. ",
      },
      {
        icon: EducationIcon,
        titie: "Education",
        description:
          "Generate WAEC and NECO Result Checker PINs and Tokens at unbeatable prices.",
      },
      {
        icon: Tv,
        titie: "TV Subscription",
        description:
          "Instantly activate Cable TV subscriptions such as DSTV, GOTV and Startimes with favourable discounts.",
      },
      {
        icon: Auto,
        titie: "Auto Buy",
        description:
          "Print recharge cards of all networks in your desired business name",
      },
    ],
    Arr3 = [
      {
        icon: One,
        title: "Download and Install the App",
        desc: "Visit your app store, search for ‘Honour World’, download and install the app on your mobile device.",
      },
      {
        icon: Two,
        title: "Sign Up on Honour World for free",
        desc: "Open the app and follow the fast and easy sign-up process. All you need is your basic personal information.",
      },
      {
        icon: Three,
        title: "Add Funds and Pay Bills",
        desc: "Once you’re signed in, you can add funds to your account and start paying your bills. Easy! ",
      },
    ],
    Faq = [
      {
        question: "How can I fund my wallet?",
        answer: [
          "Open our website/app: Log in to your account.",
          "Fund Wallet: Click on the Fund Wallet option",
          "Select Account: Choose the preferred account",
          "Transfer: Initiate a transfer to the provided account number or use your debit card.",
          "Instant Funding: Your wallet will be credited instantly.",
        ],
      },
      {
        question: "How can I purchase data?",
        answer: [
          "Access our website/app: Log in to your account.",
          "Data Purchase: Click on the data section.",
          "Choose Details: Select your desired network, enter the phone number, and choose the data value.",
          "Payment: Complete the payment process.",
          "Instant Delivery: You'll receive the purchased data instantly.",
        ],
      },
      {
        question: "How do I earn a commission?",
        answer: [
          "Log in: Sign in to your account.",
          "Navigate to Profile: Click on the profile section.",
          "Select My Commission List: Lists of transactions that gives commissions will be displayed.",
          "Commission Transfer: Commissions can be transferred from your commission wallet to your main wallet for subsequent purchases.",
        ],
      },
      {
        question: "How can I withdraw my commission?",
        answer: [
          "Log in: Sign in to your account.",
          "Transfer Balance: Click on Transfer Balance.",
          "Select Transfer: Choose the transfer type and enter amount.",
          "Proceed: Click proceed to initiate the transfer.",
          "Instant Transfer: Your commission will be transferred instantly to your main wallet.",
        ],
      },
      {
        question: "Can I convert Airtime to Cash?",
        answer: [
          "Access our website/app: Log in to our website/App.",
          "Airtime to Cash: Select the Airtime to Cash option.",
          "Enter Details: Provide network, sender's number, bank name, account number, and transfer amount.",
          "Manual Transfer: Manually transfer the airtime via USSD or via the mobile app of the network provider and take a screenshot.",
          "Instant Cash: You will receive payment within 24 hours or before the close of business for the day.",
        ],
      },
      {
        question: "How do I check my data balance?",
        answer: [
          "For MTN CG: Dial *323*4#",
          "For MTN SME: Dial *310#",
          "For Airtel: Dial *323#",
          "For Glo: Dial *310#",
          "For 9mobile: Dial *228#",
        ],
      },
    ];
  const marqueeContent = document.querySelector(".marquee-content");

  let marqueeRef = useRef();

  // const ScrollLeft = () => {
  // 	const marqueeContent2 = marqueeRef.current;
  // 	const offset = marqueeContent2.ScrollLeft - 3;
  // 	marqueeContent2.ScrollLeft = offset;
  // };
  const handleFaq = (i) => {
    setOpen(i);
    setAnswer(!answer);
  };

  const ToggleAnimation = () => {
    marqueeContent.style.animationPlayState = isPaused ? "running" : "paused";
    setIsPaused(!isPaused);
  };
  return (
    <div className="homeTwo ">
      {/* <div className="tw-bg-[#000614] tw-fixed tw-inset-0"></div> */}
      <div className="tw-relative tw-z-20 matter">
        <Nav />
        <section id="home" className="container">
          <div className=" tw-mx-auto tw-px-4">
            <div className="tw-grid tw-mt-24 lg:tw-mt-0 tw-relative md:tw-grid-cols-2 tw-h-screen tw-items-center tw-gap-8">
              <div
                className=""
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                <p className="">
                  <span className="tw-text-7xl general md:tw-text-8xl tw-text-black tw-font-bold">
                    Pay your bills without{" "}
                  </span>
                  <span className="tw-text-[#2F75FD] tw-italic matter tw-text-7xl tw-font-bold md:tw-text-8xl">
                    {" "}
                    <span className="tw-text-[#2F75FD] tw-italic matter tw-text-7xl tw-font-bold md:tw-text-8xl">
                      Stress
                    </span>
                    <img
                      src={Lines}
                      alt=""
                      className="tw-ml-80 tw-hidden lg:tw-block"
                    />
                  </span>
                </p>
                <p className="tw-text-black tw-text-2xl general tw-py-8 tw-max-w-lg">
                  Simplify your Bill Payments, Earn Commissions and get a
                  Convenient, Hassle-Free Experience.
                </p>
                <div className="tw-mt-4 tw-flex tw-gap-5">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ng.honourworld"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Google}
                      alt=""
                      className="tw-rounded-full tw-h-10 lg:tw-h-full"
                    />
                  </a>
                  <a
                    href="https://testflight.apple.com/join/IkKzsOmN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Ios}
                      alt=""
                      className="tw-rounded-full tw-h-10 tw-justify-center lg:tw-justify-center tw-gap-5 lg:tw-h-full"
                    />
                  </a>
                </div>
                <div className="tw-flex tw-gap-4 tw-mt-10 tw-items-center">
                  <img src={People} alt="" className="" />
                  <div>
                    <h6 className="tw-text-2xl tw-text-[#2F75FD] tw-font-semibold general">
                      24K + <br />
                      <span className="tw-text-xs tw-text-black general tw-font-medium">
                        Reviews
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="" data-aos="zoom-in-left">
                <img src={HeroImg} alt="" className="" />
              </div>
            </div>
          </div>
        </section>
        <section
          style={{
            background: `url(${Bg2})`,
            backgroundRepeat: "no-repeat",
          }}
          className="tw-w-full tw-bg-white tw-bg-opacity-90 -tw-mt-24"
        >
          <div
            ref={marqueeRef}
            onMouseEnter={ToggleAnimation}
            onMouseLeave={ToggleAnimation}
            className="tw-w-full marquee-container tw-overflow-hidden tw-whitespace-nowrap tw-py-8 tw-px-10 tw-bg-opacity-60 tw-backdrop-blur-lg tw-cursor-pointer"
          >
            <div className="tw-flex marquee-content tw-gap-10 tw-items-center">
              <div className="tw-flex tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>

              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>

              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>

              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
              <div className="tw-flex tw-ml-8 tw-gap-2 tw-items-center">
                <img src={Star} alt="" className="" />
                <div>
                  <h2 className="general tw-text-3xl tw-font-medium tw-text-[#2F75FD]">
                    OVER{" "}
                    <span className="tw-font-bold general tw-text-3xl tw-text-[#06EF79]">
                      100K
                    </span>{" "}
                    DOWNLOADS
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            id="about"
            className="tw-mt-48 tw-mb-12 tw-grid lg:tw-grid-cols-2 tw-gap-6 lg:tw-w-1/2 tw-w-5/6 tw-mx-auto"
          >
            {Arr.map((item, i) => (
              <div
                data-aos="zoom-in-up"
                data-aos-duration="1500"
                key={i}
                className="tw-bg-white tw-h-32 tw-shadow-xl tw-px-10 tw-flex tw-gap-4 tw-rounded tw-items-center"
              >
                <img src={item.icon} alt="" className="" />
                <div>
                  <p className="tw-text-black tw-text-xl general tw-font-normal">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section id="services" className="tw-mt-32">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            className="tw-text-[32px] tw-text-center tw-font-semibold general tw-text-[#2F75FD]"
          >
            Our Services{" "}
            <span className="tw-text-[32px] tw-text-center tw-font-semibold general tw-text-black">
              Especially for you
            </span>
          </h2>
          <p
            data-aos="fade-in"
            data-aos-duration="1500"
            className="tw-text-2xl tw-pt-6 tw-font-medium tw-max-w-xl tw-mx-auto tw-text-black general tw-text-center"
          >
            We offer you very cheap rates, huge discounts and instant
            commissions in your wallet.
          </p>
          <div
            style={{
              background: `url(${Bg3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="tw-w-full tw-hidden md:tw-block tw-pt-20 tw-pb-10"
          >
            <div className="container ">
              <div className="tw-grid lg:tw-grid-cols-3 tw-gap-14 lg:tw-w-3/4 tw-mx-auto">
                {Arr2.map((item, i) => (
                  <div
                    data-aos="zoom-in-up"
                    data-aos-duration="2000"
                    style={{
                      background: "rgba(255, 255, 255, 0.50)",
                      backdropFilter: "blur(1.6949727535247803px)",
                      borderRadius: "10.17px",
                      border: "0.847px solid #2F75FD",
                    }}
                    key={i}
                    className="tw-px-6  tw-pt-10 tw-bg-black"
                  >
                    <img src={item.icon} alt="" className="" />
                    <div className="tw-mt-6">
                      <h4 className="tw-text-xl tw-text-black tw-font-semibold general">
                        {item.titie}
                      </h4>
                      <p className="tw-pt-2 tw-text-lg tw-font-medium tw-text-[#1b1b1b]">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="tw-block lg:tw-hidden">
            {" "}
            <Swiper
              direction={"horizontal"}
              centeredSlides={true}
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              mousewheel={true}
              modules={[Mousewheel, Autoplay]}
              className=" mySwiper tw-mt-10"
            >
              {Arr2.map((item, i) => (
                <SwiperSlide>
                  <div
                    style={{
                      background: "rgba(255, 255, 255, 0.50)",
                      backdropFilter: "blur(1.6949727535247803px)",
                      borderRadius: "10.17px",
                      border: "0.847px solid #2F75FD",
                    }}
                    key={i}
                    className="tw-px-6 tw-w-72 tw-mx-auto tw-pt-10 tw-bg-black"
                  >
                    <img src={item.icon} alt="" className="" />
                    <div className="tw-mt-6">
                      <h4 className="tw-text-xl tw-text-black tw-font-semibold general">
                        {item.titie}
                      </h4>
                      <p className="tw-pt-2 tw-text-lg tw-font-medium tw-text-[#1b1b1b]">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <section className="tw-w-full tw-mt-16">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            className="tw-text-[32px] tw-text-center tw-font-semibold general tw-text-[#2F75FD]"
          >
            3 Easy Steps{" "}
            <span className="tw-text-[32px] tw-text-center tw-font-semibold general tw-text-black">
              to Enjoy Honour World
            </span>
          </h2>
          <div className="container tw-mt-16 tw-grid lg:tw-grid-cols-2 tw-items-center tw-gap-20">
            <img
              src={Steps}
              alt=""
              className=""
              data-aos="fade-in"
              data-aos-duration="1500"
            />
            <div className="tw-space-y-10">
              {Arr3.map((item, i) => (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  key={i}
                  className=""
                >
                  <img
                    src={item.icon}
                    alt=""
                    className="tw-mx-auto lg:tw-mx-0"
                  />
                  <h6 className="tw-text-xl  lg:tw-text-left tw-text-center  tw-pt-3 tw-font-semibold tw-text-[#2F75FD] general">
                    {item.title}
                  </h6>
                  <p className="general tw-max-w-lg lg:tw-text-left tw-text-center tw-text-lg tw-w-96 lg:tw-w-full lg:tw-mx-0 tw-mx-auto lg:tw-text-[15px] tw-font-normal tw-text-[#4F4F4F]">
                    {item.desc}
                  </p>
                </div>
              ))}
              <div className="tw-flex tw-justify-center tw-mt-6 lg:tw-justify-start">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ng.honourworld"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="tw-h-14 tw-px-6 tw-flex tw-gap-4 tw-items-center tw-bg-[#06EF79] tw-rounded-full ">
                    <span className="tw-text-base general tw-font-bold tw-text-black uppercase">
                      Download app
                    </span>
                    <img src={Right} alt="" className="" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="tw-w-full tw-mt-20">
          <h2 className="tw-text-center tw-text-[32px] tw-font-semibold general tw-text-black">
            <span className="tw-text-center tw-text-[32px] tw-font-semibold general tw-text-[#2F75FD]">
              Answers
            </span>{" "}
            to our most <br /> frequently asked questions
          </h2>
          <div
            // style={{
            //   background: `url(${Bg3})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            // }}
            className="tw-w-full"
          >
            <div
              style={{
                borderRadius: "53px",
                background: "rgba(205, 225, 255, 0.50)",
              }}
              className="container relative tw-pt-20 tw-mt-20 tw-backdrop-blur"
            >
              <div className="lg:tw-w-3/4 tw-mx-auto tw-pb-10">
                {Faq?.map((item, i) => (
                  <div
                    key={i}
                    className="tw-border-b tw-border-t-black tw-border-b-black tw-py-5 tw-px-3"
                  >
                    <div
                      onClick={() => handleFaq(i)}
                      className="tw-flex tw-cursor-pointer tw-justify-between tw-items-center"
                    >
                      <h4 className="tw-text-2xl tw-font-medium tw-text-black general">
                        {item.question}
                      </h4>
                      <div>
                        {(open === i) & answer ? (
                          <IconContext.Provider
                            value={{ className: "tw-translate-y-30" }}
                          >
                            <AiOutlineMinusCircle size={20} />
                          </IconContext.Provider>
                        ) : (
                          <AiOutlinePlusCircle size={20} />
                        )}
                      </div>
                    </div>
                    {open === i && (
                      <ul className="tw-list-disc">
                        {item.answer.map((ans, i) => (
                          <li
                            key={i}
                            className={
                              answer
                                ? "w-text-base tw-text-black tw-font-normal general"
                                : "tw-hidden"
                            }
                          >
                            {ans}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              <div
                style={{
                  background: `url(${Bg4})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className="tw-h-[400px] tw-px-6 tw-grid lg:tw-grid-cols-2 tw-mt-60 tw-w-full"
              >
                {" "}
                <img
                  src={Iphone}
                  alt=""
                  className="tw-h-[800px] tw-hidden lg:tw-block -tw-mt-56"
                />
                <div className="tw-pt-28">
                  <h3 className="tw-text-5xl general tw-w-96 tw-font-bold tw-text-white">
                    Try Honour World for{" "}
                    <span className="tw-text-5xl general tw-w-96 tw-font-bold tw-text-[#06EF79]">
                      free
                    </span>{" "}
                    on iOS and Andriod
                  </h3>
                  <div className="tw-mt-4 tw-flex tw-gap-5">
                    <a
                      style={{
                        textDecoration: "none !important",
                      }}
                      href="https://play.google.com/store/apps/details?id=com.ng.honourworld"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Google}
                        alt=""
                        className="tw-rounded-full tw-h-10 lg:tw-h-full"
                      />
                    </a>
                    <a
                      href="https://testflight.apple.com/join/IkKzsOmN"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Ios}
                        alt=""
                        className="tw-rounded-full tw-h-10 tw-justify-center lg:tw-justify-center tw-gap-5 lg:tw-h-full"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterSection />
        {/* <FAQ />
        <Footer /> */}
      </div>
    </div>
  );
};
export const FooterSection = () => {
  let { settings } = useSelector((s) => s);
  const navigate = useNavigate();
  const Arr2 = [
    {
      title: "Services",
      details: [
        { name: "Data Bundle Purchases" },
        { name: "Mobile Airtime top-ups" },
        { name: "Education Bill Payments" },
        { name: "Cable TV Subscriptions" },
        { name: "Electricity Bill Payments" },
      ],
    },
    {
      title: "Company",
      details: [
        { name: "Home", url: "/" },
        { name: "Services", url: "/services" },
        // { name: "Blog", url: "/blogs" },
        { name: "About Us", url: "/about" },
        { name: "Privacy Policy", url: "/privacy" },
      ],
    },
    {
      title: "Info",
      details: [
        { name: settings?.settings?.accountTelephone || "07051451125" },
        {
          name: settings?.settings?.accountEmail || "support@honourworld.com",
        },
      ],
    },
    // {
    //   title: "Contact",
    //   details: [
    //     {
    //       icon: Twitter,
    //       text: "Twitter",
    //       url: "",
    //     },
    //     {
    //       icon: Facebook,
    //       text: "Facebook",
    //       url: "",
    //     },
    //     {
    //       icon: Instagram,
    //       text: "Instagram",
    //       url: "",
    //     },
    //     {
    //       icon: Linkedin,
    //       text: "Linkedin",
    //       url: "",
    //     },
    //     {
    //       icon: Whatsapp,
    //       text: "Whatsapp",
    //       url: "",
    //     },
    //   ],
    // },
  ];
  const ContactsArr = [
    {
      icon: Twitter,
      text: "Twitter",
      url: process.env.REACT_APP_AGENT_TWITTER
        ? process.env.REACT_APP_AGENT_TWITTER
        : "",
    },
    {
      icon: Facebook,
      text: "Facebook",
      url: process.env.REACT_APP_AGENT_FACEBOOK
        ? process.env.REACT_APP_AGENT_FACEBOOK
        : "",
    },
    {
      icon: Instagram,
      text: "Instagram",
      url: process.env.REACT_APP_AGENT_INSTAGRAM
        ? process.env.REACT_APP_AGENT_INSTAGRAM
        : "",
    },
    {
      icon: Whatsapp,
      text: "Whatsapp",
      url: process.env.REACT_APP_AGENT_WHATSAPP
        ? process.env.REACT_APP_AGENT_WHATSAPP
        : "",
    },
  ];
  return (
    <section
      id="contact"
      className="tw-w-full tw-mt-40 tw-bg-[#2F75FD] lg:tw-py-16 tw-py-8"
    >
      <div className="container">
        <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-y-5 tw-space-y-2 md:tw-space-y-0 tw-gap-6 lg:tw-gap-24">
          <div>
            <div className="tw-flex tw-gap-4 tw-items-center">
              <img src={Logowhite} alt="" className="" />
              <div>
                <p className="tw-text-[11px] Montserrat tw-font-medium tw-text-white tw-text-right">
                  RC - 1705767
                </p>
                <h6 className="Montserrat tw-text-xl tw-pb-4 tw-font-extrabold tw-text-white ">
                  Honour World <br /> Limited
                </h6>
              </div>
            </div>
            <p className="tw-font-medium general tw-text-lg tw-text-white tw-w-96 lg:tw-pt-9">
              Honour World is a top payment service provider that enables users
              to easily and securely pay for various bills and subscriptions,
              including electricity, internet, airtime, data bundles, education
              bills, cable TV, and more.
            </p>
            <p className="tw-font-medium general tw-text-lg tw-text-white tw-w-96 lg:tw-pt-12">
              Address: Lagos, Nigeria
            </p>
          </div>
          <div className="tw-grid lg:tw-grid-cols-4 tw-grid-cols-2 tw-gap-16">
            {Arr2.map((item, i) => (
              <div key={i}>
                <p className="tw-text-white general tw-capitalize tw-text-lg tw-font-semibold">
                  {item.title}
                </p>
                <div className="tw-mt-4">
                  {item.details.map((item, i) => (
                    <p
                      onClick={() => navigate(item?.url)}
                      key={i}
                      className="tw-text-white tw-cursor-pointer general tw-text-lg tw-font-medium"
                    >
                      {item?.name}
                    </p>
                  ))}
                </div>
              </div>
            ))}
            <div>
              <p className="tw-text-white general tw-capitalize tw-text-lg tw-font-semibold">
                Contacts
              </p>
              <div className="tw-space-y-4 tw-mb-3 lg:tw-mb-0">
                {ContactsArr.map((item, i) => (
                  <div
                    key={i}
                    className="tw-flex tw-items-center tw-h-full tw-gap-4"
                  >
                    <img src={item.icon} alt="" className="tw-h-6" />
                    <Link to={item?.url}>
                      <small className="tw-text-white general tw-capitalize tw-text-lg tw-font-medium">
                        {item.text}
                      </small>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className=" tw-border-y tw-border-y-white tw-py-8 tw-flex tw-justify-between tw-items-center">
          <div>
            <h6 className="tw-text-lg tw-text-white general tw-font-medium">
              2024 Honour World. All rights reserved
            </h6>
          </div>
          <div className="tw-flex tw-gap-12">
            <h6 className="tw-text-lg tw-text-white general tw-font-medium">
              Privacy policy
            </h6>
            <h6 className="tw-text-lg tw-text-white general tw-font-medium">
              Terms of use
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeTwo;
export const Junks = () => {
  return (
    <div>
      <div className="container tw-my-10 tw-px-4">
        <div className="tw-grid md:grid-cols-2 tw-gap-8">
          <div
            className="tw-order-2 md:tw-order-1"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <div className="tw-flex tw-gap-x-4 tw-items-center">
              <div className="tw-overflow-hidden tw-rounded tw-w-36 tw-h-36">
                <img
                  className=" tw-w-full"
                  src={require("../../Assets/large-avatar.png")}
                  alt="Extra large avatar"
                />
              </div>
              <div className="tw-self-center">
                <span className="tw-text-[#2F75FD] tw-mb-8">
                  <FaQuoteRight size={18} />
                </span>
                <p className="tw-text-black tw-text-xs md:text-md tw-max-w-md tw-mt-4">
                  I was hesitant to switch to an online platform for my
                  subscriptions initially, but{" "}
                  <span className="tw-text-[#2F75FD] tw-text-xs md:text-md ">
                    HonorWorld
                  </span>{" "}
                  has exceeded my expectations.
                </p>
              </div>
            </div>
          </div>
          <div
            className="tw-order-1 md:tw-order-2"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <div className="tw-flex tw-justify-around tw-align-baseline tw-gap-10">
              <div>
                <p className="tw-text-black md:tw-text-2xl">Latest review</p>
                <div className="tw-flex tw-items-center tw-gap-4">
                  <div className="tw-flex tw-mb-5 -tw-space-x-4">
                    <img
                      className="tw-h-10 tw-w-10 sm:tw-w-16 sm:tw-h-16 tw-border-2 tw-border-[#2F75FD] tw-rounded-full"
                      src={require("../../Assets/Ellipse-1.png")}
                      alt=""
                    />
                    <img
                      className="tw-h-10 tw-w-10 sm:tw-w-16 sm:tw-h-16 tw-border-2 tw-border-[#2F75FD] tw-rounded-full"
                      src={require("../../Assets/Ellipse-2.png")}
                      alt=""
                    />
                    <img
                      className="tw-h-10 tw-w-10 sm:tw-w-16 sm:tw-h-16 tw-border-2 tw-border-[#2F75FD] tw-rounded-full"
                      src={require("../../Assets/Ellipse-3.png")}
                      alt=""
                    />
                    <img
                      className="tw-h-10 tw-w-10 sm:tw-w-16 sm:tw-h-16 tw-border-2 tw-border-[#2F75FD] tw-rounded-full"
                      src={require("../../Assets/Ellipse-4.png")}
                      alt=""
                    />
                    <img
                      className="tw-h-10 tw-w-10 sm:tw-w-16 sm:tw-h-16 tw-border-2 tw-border-[#2F75FD] tw-rounded-full"
                      src={require("../../Assets/Ellipse-5.png")}
                      alt=""
                    />
                  </div>
                  <p className="md:tw-text-4xl tw-font-bold tw-text-black tw-flex tw-gap-1 tw-items-center">
                    50k{" "}
                    <span className="tw-text-[#2F75FD]">
                      <BiPlus size={18} />
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <p className="tw-text-[#1b1b1b] md:tw-text-2xl">
                  Latest review
                </p>
                <div className="md:tw-text-4xl tw-font-bold tw-text-black tw-flex tw-gap-4 tw-items-center">
                  <span className="tw-text-[#2F75FD]">
                    <BsFillStarFill size={20} />
                  </span>
                  4.9
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="about" className="container">
        <div className="tw-py-20 tw-px-4">
          <div className="tw-grid md:tw-grid-cols-2 tw-gap-8">
            <div>
              <p
                className="tw-text-3xl md:tw-text-6xl tw-text-black tw-max-w-lg mb-4"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                Take Control of Your Subscriptions with
                <span className="tw-text-3xl md:tw-text-6xl tw-text-[#2F75FD]">
                  {" "}
                  HonorWorld{" "}
                </span>
              </p>
              <p
                className="tw-text-black tw-max-w-md tw-mt-4 tw-font-light"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                Empower Your Financial Journey with the Ultimate Solution for
                Convenience and experience the ease and security of managing
                your finances with our innovative and comprehensive solution.
              </p>
              <div data-aos="zoom-in-up" data-aos-duration="2000">
                <img
                  src={require("../../Assets/iPhone-pro.png")}
                  alt=""
                  className="tw-relative tw-z-10 tw-h-[400p] mx-auto"
                />
              </div>
            </div>
            <div className="tw-space-y-4">
              <div
                className="tw-block tw-max-w-xl tw-p-6 tw-bg-white tw-shadow-2xl tw-border-gray-700 tw-rounded-lg  tw-text-black hover:tw-text-[#22092B] tw-mx-auto"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <h5 className="tw-mb-2 tw-text-2xl tw-font-bold tw-tracking-tight">
                  Secure Yet Convenient Transaction
                </h5>
                <p className="tw-font-normal tw-text-gray-500">
                  Our user-friendly app & secure online platform make managing
                  your finances easy.
                </p>
              </div>
              <div
                className="tw-block tw-max-w-xl tw-p-6 tw-bg-white tw-shadow-2xl tw-border-gray-700 tw-rounded-lg  tw-text-black hover:tw-text-[#22092B] tw-mx-auto"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <h5 className="tw-mb-2 tw-text-2xl tw-font-bold tw-tracking-tight">
                  Optimum Satisfaction
                </h5>
                <p className="tw-font-normal tw-text-gray-500">
                  We offer a full range of financial services to meet all of
                  your needs.
                </p>
              </div>
              <div
                className="tw-block tw-max-w-xl tw-p-6 tw-bg-white tw-shadow-2xl tw-border-gray-700 tw-rounded-lg  tw-text-black hover:tw-text-[#22092B] tw-mx-auto"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <h5 className="tw-mb-2 tw-text-2xl tw-font-bold tw-tracking-tight">
                  Quick Delivery
                </h5>
                <p className="tw-font-normal tw-text-gray-500">
                  We use cutting-edge technology to provide a modern and
                  efficient banking solution.
                </p>
              </div>
              <div
                className="tw-block tw-max-w-xl tw-p-6 tw-bg-white tw-shadow-2xl tw-border-gray-700 tw-rounded-lg  tw-text-black hover:tw-text-[#22092B] tw-mx-auto"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <h5 className="tw-mb-2 tw-text-2xl tw-font-bold tw-tracking-tight">
                  Exceptional Customer Support
                </h5>
                <p className="tw-font-normal tw-text-gray-500">
                  Our team is available 24/7 to assist you with any questions or
                  concerns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="products" className="tw-bg-secondary tw-p-10 container">
        <p className="tw-text-3xl tw-font-bold tw-text-center tw-text-black">
          Our <span className="tw-text-3xl tw-text-[#2F75FD]">services </span>
          services Especially for you
        </p>
        <div className="tw-container tw-mx-auto">
          <div className="tw-grid md:tw-grid-cols-3 tw-gap-x-8 tw-gap-y-10 tw-mt-10">
            <Card
              img="ico-1.png"
              title="Data Services"
              text="Enjoy internet browsing always with our attractive data rates. Start your data reselling business with us today and enjoy profitable discounts for all networks."
            />
            <Card
              img="ico-2.png"
              title="Airtime Services"
              text="Making an online recharge has become very easy, fast and safe on Honourworld... We offer instant recharge of Airtime with discounts for all networks."
            />
            <Card
              img="ico-3.png"
              title="Electricity"
              text="We offer fast recharge of prepared meter with instant token delivery. E.g PHEDC, EKEDC IKEDC, AEDC e.t.c."
            />
            <Card
              img="ico-4.png"
              title="Education"
              text="Generate WAEC and NECO Result Checker PINs and Tokens at unbeatable prices"
            />
            <Card
              img="ico-5.png"
              title="Tv Subscription"
              text="Instantly activate cable TV subscriptions such as DStv, GOtv and Startimes with favourable discounts."
            />
            <Card
              img="auto.svg"
              title="Cable Subscription"
              text="Print recharge cards of all networks in your desired business name"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
