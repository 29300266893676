import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import Dashboard from "../Components/Dashboard";
import { GlobalState } from "../Data/Context";
import Wallet from "../Assets/wallet.svg";
import Success from "../Assets/success.svg";
import Sales from "../Assets/sales.svg";
// import Bg2 from "../Assets/bg2.svg";
import Expense from "../Assets/expenses.svg";
// import moment from "moment";
import { ModalComponents } from "../Components";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
// import { getWalletBalance } from "../Data/Actions/GeneralAction";
// import { getSettings } from "../Data/Reducer/SettingsReducer";
// import Store from "../Data/Store";

export const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];

const MainDashboard = () => {
  // useEffect(() => {
  //   Store.dispatch(getWalletBalance());
  //   // Store.dispatch(getSettings());
  // }, []);

  const navigate = useNavigate();

  const {
    wallet,
    numberWithCommas,
    notifications,
    nairaSignNeutral,
    auth,
    settings,
  } = useContext(GlobalState);
  let usersArr = [
    {
      name: "wallet balance",
      number: `${nairaSignNeutral} ${
        wallet?.balance?.available
          ? numberWithCommas(Number(wallet?.balance?.available).toFixed(2))
          : 0
      }`,
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
      link: "/wallets",
      type: "amount",
      icon: Wallet,
      bg: " #2986E4",
      icon2: "fontisto:wallet",
    },

    {
      name: "today's successful transaction count",
      number: numberWithCommas(
        Number(
          wallet?.wallet_stat?.dayCount ||
            wallet?.wallet_details?.transactions?.dayCount ||
            wallet?.wallet_details?.dayCount ||
            0
        )
      ),
      icon2: "grommet-icons:transaction",
      color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      icon: Success,
      link: "/transactions",
      bg: "#03AD3C",
    },
    // {
    // 	name: "total products",
    // 	number: productArr?.length,
    // 	color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
    // 	link: "/products",
    // 	icon: icon3,
    // },
    {
      name: "todays' sales",
      number: `${nairaSignNeutral}${
        wallet?.wallet_details?.transactions?.day
          ? numberWithCommas(
              Number(wallet?.wallet_details?.transactions?.day).toFixed(2)
            )
          : 0
      }`,
      // color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
      color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      link: "/transactions",
      icon: Sales,
      icon2: "icon-park-solid:sales-report",
      bg: " #2986E4",
    },
    !["reseller", "agent"]?.includes(auth?.user?.privilege)
      ? {
          name: "total expenses",
          number: `${nairaSignNeutral} ${
            wallet?.wallet_details?.purchase
              ? numberWithCommas(
                  Number(wallet?.wallet_details?.purchase).toFixed(2)
                )
              : 0
          }`,
          color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
          link: "/transactions",
          icon: Expense,
          icon2: "arcticons:day-to-day-expenses",
          bg: "#03AD3C",
        }
      : {
          name: "today's successful data sold",
          number: `${numberWithCommas(
            Number(
              wallet?.wallet_stat?.dayDataCount ||
                wallet?.wallet_details?.transactions?.dayDataCount ||
                wallet?.wallet_details?.dayDataCount ||
                0
            ).toFixed(2)
          )}GB`,
          color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
          icon: Expense,
          button: true,
        },
  ];

  let [notifyList, setNotifyList] = useState([]),
    [isOpen, setIsOpen] = useState(false),
    [keepOpen, setKeepOpen] = useState(true);

  useEffect(() => {
    if (notifications?.informed?.length > 0) {
      let res = notifications?.informed?.filter(
        (item) => item?.priority && item?.status === "play"
      );
      if (res?.length > 0) setNotifyList(res);
    }
  }, [notifications?.informed]);

  useEffect(() => {
    if (notifyList?.length > 0 && !isOpen) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
    if (notifyList?.length === 0 || !keepOpen) setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyList?.length]);

  let markAsNotified = async () => {
    setIsOpen(false);
  };

  const marqueeContent = document.querySelector(".marquee-content2");

  let marqueeRef = useRef(),
    [isPaused, setIsPaused] = useState(null);

  const ToggleAnimation = () => {
    marqueeContent.style.animationPlayState = isPaused ? "running" : "paused";
    setIsPaused(!isPaused);
  };

  return (
    <>
      <div className=" tw-gap-2 tw-mt-4 lg:tw-w-5/6 tw-w-11/12  tw-bg-white tw-mx-auto">
        <section
          style={{
            backgroundRepeat: "no-repeat",
          }}
          className="tw-w-full tw-bg-white"
        >
          <div
            ref={marqueeRef}
            onMouseEnter={ToggleAnimation}
            onMouseLeave={ToggleAnimation}
            className="tw-w-full tw-relative tw-rounded-xl marquee-container tw-overflow-hidden tw-whitespace-nowrap bg-danger tw-py-7 md:tw-px-10 tw-bg-opacity-60 tw-backdrop-blur-lg tw-cursor-pointer"
          >
            <div className="tw-absolute tw-top-0 tw-h-full tw-left-2 tw-flex tw-gap-2 tw-items-center bg-danger tw-z-50">
              <Icon
                color={"white"}
                style={{
                  fontSize: "32px",
                }}
                icon={"fxemoji:star"}
              />
              <small className="tw-text-2xl tw-font-extrabold tw-text-white Lexend">
                NEW
              </small>
            </div>
            <small className="md:tw-px-10 marquee-content2 Lexend tw-text-2xl tw-text-white">
              {settings?.settings?.homepageMarquee ||
                `A new CBN policy requires all virtual accounts to be linked to
              both BVN and NIN. This applies to all linked accounts on our
              platform. Kindly link yours now!`}
            </small>
          </div>
        </section>
      </div>
      <div
        style={{
          background: "transparent !important",
        }}
        className="tw-grid lg:tw-grid-cols-4 tw-gap-2 tw-mt-4 lg:tw-w-5/6 tw-w-11/12 tw-mx-auto"
      >
        {usersArr.map((item, i) => (
          <div
            style={{
              background: item?.bg,
            }}
            key={i}
            onClick={item?.link ? () => navigate(`${item?.link}`) : null}
            className="tw-p-5 tw-flex tw-rounded-xl tw-items-center tw-cursor-pointer tw-justify-between tw-shadow-xl"
          >
            <div>
              <Icon
                color={"white"}
                style={{
                  fontSize: "36px",
                }}
                icon={item?.icon2}
              />
              {/* <img src={item?.icon} alt="" className="tw-h-16" /> */}
            </div>
            <div>
              <h4 className="tw-text-3xl tw-text-white tw-text-right tw-font-bold">
                {item?.number}
              </h4>
              <p className="tw-text-lg tw-font-semibold tw-capitalize tw-text-right tw-w-40 tw-text-white">
                {item?.name}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-flex container tw-justify-end tw-mt-8">
        <div className="px-1 p-md-3 text-center tw-mt-2">
          <div
            onClick={() => navigate("/wallets")}
            // onClick={() =>
            //   navigate(
            //     item?.link?.includes("converter")
            //       ? item?.link
            //       : `/products${item?.link}`
            //   )
            // }
            className=" py-md-3 tw-px-6 tw-bg-[#2986E4] eachProduct shadow-xl rounded20 h-100 d-flex align-items-center justify-content-center"
          >
            <div className="d-flex flex-column">
              <div className="tw-flex tw-justify-center tw-mb-2">
                <Icon
                  style={{
                    fontSize: "36px",
                  }}
                  icon={"tabler:credit-card-refund"}
                  color="white"
                  className="tw-animate-pulse"
                />
              </div>
              <h6 className="Lexend tw-text-white textTrunc textTrunc2 fontReduce2 text-capitalize">
                Fund Wallet
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Dashboard usersArr={usersArr} />

      <ModalComponents
        isOpen={isOpen}
        title="Be informed"
        size={"sm"}
        borderNone={"borderNone"}
        toggle={() => {
          setKeepOpen(false);
          markAsNotified();
        }}
      >
        <div className="downH2 d-flex flex-column">
          {notifyList?.map((item, i) => (
            <Fragment key={i}>
              {item?.image?._id && (
                <>
                  <img
                    onClick={
                      item?.linkURL
                        ? () => {
                            window.open(item?.linkURL, "_blank");
                          }
                        : null
                    }
                    src={item?.image?.url}
                    alt={item?.image?.name}
                    className={`img-fluid rounded imgFluid img-contain ${
                      item?.linkURL ? "myCursor" : ""
                    }`}
                    style={{
                      height: "auto",
                      width: "100%",
                    }}
                  />
                </>
              )}
              <p
                onClick={
                  item?.linkURL
                    ? () => {
                        window.open(item?.linkURL, "_blank");
                      }
                    : null
                }
                className={`fw-bold Lexend w-100 my-0 py-3 ${
                  item?.linkURL ? "myCursor" : ""
                }`}
              >
                <span className="fontInherit me-2">
                  {notifyList?.length > 1 && <>{i + 1}.</>}
                </span>{" "}
                <pre className="fontInherit Lexend">{item?.message}</pre>
              </p>
              {/* <small className="d-block ms-auto Lexend mb-3">
								{moment(item?.createdAt).diff(moment(), "years") < 0
									? moment(item?.createdAt).format("L hh:mm A")
									: moment(item?.createdAt).diff(moment(), "months") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).diff(moment(), "days") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).format("hh:mm A")}
							</small> */}
            </Fragment>
          ))}
          <button
            onClick={() => {
              setKeepOpen(false);
              markAsNotified();
            }}
            className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto"
          >
            close
          </button>
        </div>
      </ModalComponents>
    </>
  );
};

export default MainDashboard;
